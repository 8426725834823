import React from 'react';
import { HelloApi } from '../external-services/hello-world-service';

/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */

interface State {
  helloApiResponse: string;
}

interface Props{}
/**
 * Top level React component
 */
export class Main extends React.Component<Props, State> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {
      helloApiResponse: 'Hello world from api'
    };
  }

  async componentDidMount(): Promise<void> {
    const helloServiceResponse = await this.callHelloWorldApi();
    this.setState({
      helloApiResponse: helloServiceResponse
    });
    console.log('test');
  }

  /* istanbul ignore next */
  async callHelloWorldApi(): Promise<string> {
    const api = new HelloApi();
    const response = await api.HelloCallApi();
    console.log('resp', response);
    return response;
  }

  /**
   * Render function
   */
  render() {
    // console.log('this.state.helloApiResponse', this.state.helloApiResponse)
    // The JSX code below is converted into React.createElement() calls by the compiler because of
    // the jsx setting in tsconfig.json
    return (
      <div>
        <p>{this.state.helloApiResponse}</p>
      </div>
    );
  }
}
