/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */

export class HelloApi {
  async HelloCallApi(): Promise<string> {
    let response = null as unknown as string;
    const requestOptions: RequestInit = {
      method: 'GET'
    };

    const helloApiPath =  'https://6hnmfx3mba.execute-api.us-east-1.amazonaws.com/prod/api/getWelcomeMessage/yaseen';
    await fetch(helloApiPath, requestOptions)
      .then((response) => response.json())
      .then((result) => response = result.Message)
      .catch((error) => console.log('error', error));
    return response;
  }
}
