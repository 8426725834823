import React from 'react';
import ReactDOM from 'react-dom';
import { Main } from './app/main';

/* const containerElement = */ document.getElementById('container-id');
ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('container-id')
);
